<script>
import GlobalVue from '../helper/Global.vue'
import Gen from '../helper/Gen'

export default {
  extends: GlobalVue,
  props:{
    background: String,
    width: Number,
    height: Number,
    top: Number,
    left: Number,
    json: Object,
  },
  data() {
    return {
      loadingInit: false,
      show: true,
      canvas: null,
      ratio: null,
      selectedObject: null,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async loadPlugin(){
      await this.ready()
      await Gen.loadScript("/plugins/fabric/fabric.min.js")
    },
    async init(){
      await this.loadPlugin()
      if(this.loadingInit) return
      this.loadingInit = true

      this.$emit("init", this)
      var parentWidth = $(this.$el).parent().width()
      var canvasWidth = this.width ? this.width : parentWidth
      this.canvas = new fabric.Canvas($(this.$el).find("canvas")[0])
      
      if(this.json){
        /* Init From JSON */
        this.ratio = this.json.backgroundImage.width / this.json.backgroundImage.height
        this.canvas.setWidth(canvasWidth ? canvasWidth : this.json.backgroundImage.width)
        this.canvas.setHeight(canvasWidth ? canvasWidth / this.ratio : this.json.backgroundImage.height)

        this.canvas.loadFromJSON(this.json, this.canvas.renderAll.bind(this.canvas));
        this.loadingInit = false
        this.$emit("ready", this)
      }else if(this.background){
        /* Init Using Background */
        fabric.Image.fromURL(this.background, (img)=>{
          this.ratio = img.width / img.height
          this.canvas.setWidth(canvasWidth ? canvasWidth : img.width)
          this.canvas.setHeight(canvasWidth ? canvasWidth / this.ratio : img.height)
          // add background image 
          this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
            scaleX: this.canvas.width / img.width,
            scaleY: this.canvas.height / img.height
          });
          this.loadingInit = false
          this.$emit("ready", this)
        },{
          crossOrigin: 'anonymous'
        });
      }else{
        this.canvas.setWidth(canvasWidth)
        this.canvas.setHeight(this.height ? this.height : canvasWidth)
        this.$emit("ready", this)
      }

      // object:modified
      this.canvas.on('object:scaling', (e) => {
        var o = e.target
        
        /* Fix Stroke Width */
        if (!o.strokeWidthUnscaled && o.strokeWidth) {
          o.strokeWidthUnscaled = o.strokeWidth;
        }
        if (o.strokeWidthUnscaled) {
          o.strokeWidth = o.strokeWidthUnscaled / o.scaleX;
        }
        /* Max scaling */
        if(o.scaleX > (o.maxScale||o.maxScaleX)) o.scaleX = (o.maxScale||o.maxScaleX)
        if(o.scaleY > (o.maxScale||o.maxScaleY)) o.scaleY = (o.maxScale||o.maxScaleY)
      })
      this.canvas.on('selection:created', (e) => {
        this.selectedObject = e.target
      })
      this.canvas.on('selection:updated', (e) => {
        this.selectedObject = e.target
      })
      this.canvas.on('selection:cleared', (e) => {
        this.selectedObject = null
      })
    },
    reinit(){
      this.show = false
      setTimeout(()=>{ this.show = true  }, 50)
      setTimeout(()=>{ this.init() }, 100)
    },
    addRect(params, options){
      var obj = new fabric.Rect(this.setObjectOptions('rect', params, options))
      this.canvas.add(obj)
    },
    addImage(url, params, options){
      fabric.Image.fromURL(url, (objImg)=>{
        params.width = objImg.width
        params.height = objImg.height
        var obj = objImg.set(this.setObjectOptions('image', params, options));
        this.canvas.add(obj);
      },{
        crossOrigin: 'anonymous'
      })
    },
    setObjectOptions(type, params, options = {}){
      setTimeout(()=>{
        if(options.center) this.lastObject().center()
        if(options.centerH) this.lastObject().centerH()
        if(options.centerV) this.lastObject().centerV()
      }, 1)
      var ratio = params.width/params.height
      if(type=="image"){
        if(options.widthPercent){
          params.scaleX = this.canvas.width / params.width * options.widthPercent / 100
          params.scaleY = params.scaleX
        }
      }else{
        if(options.widthPercent){
          params.width = this.canvas.width * options.widthPercent / 100
        }
        if(options.heightPercent){
          params.height = this.canvas.height * options.heightPercent / 100
        }
        if(options.ratio) params.height = this.canvas.height / options.ratio
      }
      /* Max scaling */
      if(params.scaleX > (params.maxScale||params.maxScaleX)) params.scaleX = (params.maxScale||params.maxScaleX)
      if(params.scaleY > (params.maxScale||params.maxScaleY)) params.scaleY = (params.maxScale||params.maxScaleY)
      return params
    },
    lastObject(){
      return this.canvas.item(this.canvas.getObjects().length-1)
    },
    bringToBack(){
      if(!this.selectedObject) return;
      this.canvas.sendToBack(this.selectedObject)
    },
    bringToFront(){
      if(!this.selectedObject) return;
      this.canvas.bringToFront(this.selectedObject)
    },
    changeFont(options){
      if(!this.selectedObject) return;
      if(!this.selectedObject.fontOptions) return;
      this.selectedObject.fontOptions = Object.assign(options)
      Gen.delay(()=>{
        var imgUrl = this.baseUrl+"/text?"+Gen.objectToQuery(this.selectedObject.fontOptions)
        this.selectedObject.setSrc(imgUrl,()=>{
          this.canvas.renderAll()
        })
      }, 100)
    },
    changeImage(url){
      if(!this.selectedObject) return;
      this.selectedObject.setSrc(url,()=>{
        this.canvas.renderAll()
      })
    },
    delete(){
      if(!this.selectedObject) return;
      VAlert.open("Apakah kamu ingin menghapus objek ini?", "", {
        theme:'default',
        image:this.selectedObject.getSrc(),
        cancel: true,
        okText: "Hapus"
      }).then((confirm)=>{
        if(!confirm) return;
        this.canvas.remove(this.selectedObject)
      })
    }
  },
  watch:{
    json(){ this.show=false; this.reinit() },
    background(){ this.show=false; this.reinit() },
  }
}
</script>

<template>
  <div VDesigner :style="'top:'+top+'px;left:'+left+'px;'" v-if="show">
    <canvas></canvas>
  </div>
</template>